
export interface IWorkExperience {
    id: string
    name: string
    occupation: string
    level: string
    period: string
    description: string
}

export const workExperience: IWorkExperience[] =
    [
        {
            id: '0',
            name: 'Källarbyn',
            occupation: "Dataadministratör",
            level: 'Deltid',
            period: "started september 2024",
            description: "Dataadministratör med fler befogenheter",
        },
        {
            id: '1',
            name: 'Operando AB',
            occupation: "Webbutvecklare",
            level: 'Praktikant',
            period: "jan-may 2024",
            description: "Arbetat med Wordpress och Advanced custom fields i PHP.",
        },
        {
            id: '2',
            name: 'iquest AB',
            occupation: "Backend-utvecklare",
            level: 'Praktikant',
            period: "oct-dec 2023",
            description: "Hanterat och uppdaterat system och kodbas i PHP.",
        },
    ]